import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export function emitDeliveryEstimate(_ref) {
  var postcode = _ref.postcode,
      labelType = _ref.labelType,
      label = _ref.label,
      _ref$shortLabel = _ref.shortLabel,
      shortLabel = _ref$shortLabel === void 0 ? null : _ref$shortLabel,
      _ref$timestamp = _ref.timestamp,
      timestamp = _ref$timestamp === void 0 ? null : _ref$timestamp,
      _ref$minRange = _ref.minRange,
      minRange = _ref$minRange === void 0 ? null : _ref$minRange,
      _ref$maxRange = _ref.maxRange,
      maxRange = _ref$maxRange === void 0 ? null : _ref$maxRange,
      confidence = _ref.confidence,
      parcelType = _ref.parcelType,
      deliveryType = _ref.deliveryType;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:au.com.catch/delivery_estimate/jsonschema/1-0-0",
      data: {
        postcode: postcode,
        labelType: labelType,
        label: label,
        shortLabel: shortLabel,
        timestamp: timestamp,
        minRange: minRange,
        maxRange: maxRange,
        confidence: confidence,
        parcelType: parcelType,
        deliveryType: deliveryType
      }
    }
  });
}