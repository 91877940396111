import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import shouldForwardProp from "@styled-system/should-forward-prop";
import { border, color, compose, cursorProp, flexbox, gridReplacementProps, layout, position, shadow, space, styled, system, typography } from "@catchoftheday/theme";
import { Box } from "../Box";
var customProps = system({
  flexFlow: true
});
var styleProps = compose(cursorProp, space, layout, position, color, typography, shadow, border, flexbox, customProps, gridReplacementProps);
export var Flex = styled(Box, {
  shouldForwardProp: shouldForwardProp
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  ", ";\n"])), styleProps);
Flex.displayName = "Flex";
export default Flex;