import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { Placeholder } from "./Placeholder";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductCardPlaceholder = function ProductCardPlaceholder(props) {
  var theme = useTheme();
  return ___EmotionJSX(Box, _extends({
    border: "1px solid ".concat(theme.colors.ui.borderColor),
    backgroundColor: "white"
  }, props), ___EmotionJSX(Placeholder, {
    uniqueKey: props.uniqueKey,
    viewBox: "0 0 250 450"
  }, ___EmotionJSX("rect", {
    x: "0",
    y: "0",
    width: "250",
    height: "200"
  }), ___EmotionJSX("rect", {
    x: "25",
    y: "220",
    width: "200",
    height: "20"
  }), ___EmotionJSX("rect", {
    x: "25",
    y: "250",
    width: "200",
    height: "20"
  }), ___EmotionJSX("rect", {
    x: "100",
    y: "280",
    width: "50",
    height: "20"
  }), ___EmotionJSX("rect", {
    x: "80",
    y: "320",
    width: "90",
    height: "40"
  })));
};