import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import shouldForwardProp from "@styled-system/should-forward-prop";
import { background, border, color, compose, cursorProp, flexbox, float as _float, grid, gridReplacementProps, layout, position, shadow, space, styled, textTransformProp, typography, visibilityProp } from "@catchoftheday/theme";
export var boxStyleProps = compose(visibilityProp, cursorProp, space, layout, background, position, color, typography, shadow, border, flexbox, grid, textTransformProp, _float, gridReplacementProps);
export var Box = styled("div", {
  shouldForwardProp: shouldForwardProp
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), boxStyleProps);
Box.displayName = "Box";
export default Box;