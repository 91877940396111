import { trackStructEvent } from "@snowplow/browser-tracker";
export function emitChristmasDeliveryWidget(_ref) {
  var category = _ref.category,
      action = _ref.action,
      label = _ref.label,
      property = _ref.property;
  trackStructEvent({
    category: category,
    action: action,
    label: label,
    property: property
  });
}