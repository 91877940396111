import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// @ts-ignore

/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */

export var CustomerDocument = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query Customer {\n  customer {\n    id\n    displayName\n    email\n    club {\n      active\n      onFreeTrial\n      freeTrialEligible\n    }\n    membership {\n      id\n      linked\n      doubleSubscription\n      eligibleForMigration\n      subscription {\n        active\n        activeDaysRemaining\n        autoRenewing\n        freeTrialEligible\n        interval\n        latestMembershipPeriodEnd\n        onFreeTrial\n        purchasePrice\n        freeTrialDaysRemaining\n        isTeamMember\n      }\n    }\n    storeCredit {\n      credit {\n        amount\n      }\n      expiry\n    }\n    metaData {\n      customerUuid\n    }\n  }\n}\n    "])));
/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *   },
 * });
 */

export function useCustomerQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(CustomerDocument, options);
}
export function useCustomerLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(CustomerDocument, options);
}
export var BannerByPageDocument = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query BannerByPage($input: BannerByPageInput!) {\n  bannerByPage(input: $input) {\n    banners {\n      id\n      url\n      platforms\n      image {\n        url\n        altText\n        height\n        width\n      }\n    }\n  }\n}\n    "])));
/**
 * __useBannerByPageQuery__
 *
 * To run a query within a React component, call `useBannerByPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerByPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerByPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */

export function useBannerByPageQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(BannerByPageDocument, options);
}
export function useBannerByPageLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(BannerByPageDocument, options);
}
export var CartContextDocument = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    query CartContext {\n  cart {\n    orderSummary {\n      itemSummary {\n        items {\n          id\n          offer {\n            variant {\n              product {\n                id\n              }\n            }\n          }\n        }\n      }\n      totalPrice {\n        amount\n      }\n    }\n  }\n}\n    "])));
/**
 * __useCartContextQuery__
 *
 * To run a query within a React component, call `useCartContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartContextQuery({
 *   variables: {
 *   },
 * });
 */

export function useCartContextQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(CartContextDocument, options);
}
export function useCartContextLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(CartContextDocument, options);
}
export var MembershipDocument = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    query Membership {\n  customer {\n    id\n    membership {\n      id\n      linked\n      subscription {\n        freeTrialEligible\n      }\n    }\n  }\n}\n    "])));
/**
 * __useMembershipQuery__
 *
 * To run a query within a React component, call `useMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipQuery({
 *   variables: {
 *   },
 * });
 */

export function useMembershipQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(MembershipDocument, options);
}
export function useMembershipLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(MembershipDocument, options);
}
export var PageConfigurationDocument = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    query PageConfiguration {\n  cmsConfiguration {\n    page {\n      itunesAppUrl\n      footerItunesAppUrl\n      homeUspItunesAppUrl\n      googlePlayUrl\n      footerGooglePlayUrl\n      homeUspGooglePlayUrl\n    }\n  }\n}\n    "])));
/**
 * __usePageConfigurationQuery__
 *
 * To run a query within a React component, call `usePageConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageConfigurationQuery({
 *   variables: {
 *   },
 * });
 */

export function usePageConfigurationQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(PageConfigurationDocument, options);
}
export function usePageConfigurationLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(PageConfigurationDocument, options);
}
export var AddItemToWishListDocument = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    mutation AddItemToWishList($variantId: ID!, $notify: Boolean) {\n  addItemToWishlist(input: {variantId: $variantId, notify: $notify}) {\n    ... on WishlistItem {\n      id\n      productId\n      variantId\n      notify\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n    "])));

/**
 * __useAddItemToWishListMutation__
 *
 * To run a mutation, you first call `useAddItemToWishListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemToWishListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemToWishListMutation, { data, loading, error }] = useAddItemToWishListMutation({
 *   variables: {
 *      variantId: // value for 'variantId'
 *      notify: // value for 'notify'
 *   },
 * });
 */
export function useAddItemToWishListMutation(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(AddItemToWishListDocument, options);
}
export var RemoveItemFromWishListDocument = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    mutation RemoveItemFromWishList($wishlistItemId: ID!) {\n  removeItemFromWishlist(input: {wishlistItemId: $wishlistItemId}) {\n    ... on RemoveFromWishlistItem {\n      id\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n    "])));

/**
 * __useRemoveItemFromWishListMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromWishListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromWishListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromWishListMutation, { data, loading, error }] = useRemoveItemFromWishListMutation({
 *   variables: {
 *      wishlistItemId: // value for 'wishlistItemId'
 *   },
 * });
 */
export function useRemoveItemFromWishListMutation(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(RemoveItemFromWishListDocument, options);
}