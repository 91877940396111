import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export function emitEventClick(_ref) {
  var title = _ref.title,
      subtitle = _ref.subtitle,
      source_type = _ref.source_type,
      badge_text = _ref.badge_text,
      event_id = _ref.event_id,
      position = _ref.position,
      event_type = _ref.event_type,
      schedule_entry_id = _ref.schedule_entry_id,
      click_target = _ref.click_target,
      from_product_ids = _ref.from_product_ids,
      from_product_displayed_price = _ref.from_product_displayed_price,
      from_product_offer_id = _ref.from_product_offer_id;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:au.com.catch/event_click/jsonschema/1-0-4",
      data: {
        title: title,
        subtitle: subtitle,
        source_type: source_type,
        badge_text: badge_text,
        event_id: event_id,
        position: position,
        event_type: event_type,
        schedule_entry_id: schedule_entry_id,
        click_target: click_target,
        from_product_ids: from_product_ids,
        from_product_displayed_price: from_product_displayed_price,
        from_product_offer_id: from_product_offer_id
      }
    }
  });
}