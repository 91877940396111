import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _toConsumableArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";

var _templateObject;

import { createShouldForwardProp, props } from "@styled-system/should-forward-prop";
import { border, color, compose, layout, position, space, styled, system, textDecorationProp, textStyle, textTransformProp, typography } from "@catchoftheday/theme";
var customProps = system({
  whiteSpace: true,
  textOverflow: true,
  textDecoration: true
});
var styleProps = compose(position, color, typography, space, layout, border, customProps);
var shouldForwardProp = createShouldForwardProp([].concat(_toConsumableArray(props), ["textTransform"]));
export var Text = styled("p", {
  shouldForwardProp: shouldForwardProp
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n"])), textStyle, styleProps, textTransformProp, textDecorationProp);
Text.displayName = "Text";
Text.defaultProps = {
  textStyle: "text"
};
export var Span = Text.withComponent("span", process.env.NODE_ENV === "production" ? {
  target: "ehnxcr60"
} : {
  target: "ehnxcr60",
  label: "Span"
});
Span.displayName = "Span";
Span.defaultProps = {
  textStyle: "text"
};
export default Text;