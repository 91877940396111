import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var ENV_CONFIG_PRIVATE_KEY = "__ENV_CONFIG_PRIVATE__";
export var ENV_CONFIG_PUBLIC_KEY = "__ENV_CONFIG_PUBLIC__";
/**
 * Initialises the Private Environment Config with the provided `config`.
 * The config object is added to the globalTarget scope and frozen to prevent accidental mutation.
 */

export function initialisePrivateEnvConfig(config, globalTarget) {
  if (true) throw new Error("Cannot initialise private environment config in the browser."); // Idempotent: only initialise once.

  if (ENV_CONFIG_PRIVATE_KEY in globalTarget) return; // Object is frozen to prevent accidental mutation.

  Object.defineProperty(globalTarget, ENV_CONFIG_PRIVATE_KEY, {
    value: Object.freeze(_objectSpread({}, config)),
    enumerable: true,
    configurable: false
  });
}
/**
 * Initialises the Public Environment Config with the provided `config`.
 * The config object is added to the globalTarget scope and frozen to prevent accidental mutation.
 */

export function initialisePublicEnvConfig(config, globalTarget) {
  // Idempotent: only initialise once.
  if (ENV_CONFIG_PUBLIC_KEY in globalTarget) return; // Cleanse the config of any private keys (i.e. keep only keys with `_PUBLIC` suffix).

  var cleansedConfig = Object.fromEntries(Object.entries(config).filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        key = _ref2[0];

    return key.endsWith("_PUBLIC");
  })); // Object is frozen to prevent accidental mutation.

  Object.defineProperty(globalTarget, ENV_CONFIG_PUBLIC_KEY, {
    value: Object.freeze(cleansedConfig),
    enumerable: true,
    configurable: false
  });
}