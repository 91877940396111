import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["host", "isMobile", "pageType"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext } from "react";
import { emitBannerClick } from "@catchoftheday/analytics";
import { Flex, ImageResponsive, Link } from "@catchoftheday/cg-components";
import { CustomerContext } from "@catchoftheday/customer";
import { useTheme } from "@catchoftheday/theme";
import { useBannerByPageQuery } from "@common-vendors/generated/graphql";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PageBanner = function PageBanner(_ref) {
  var _customerData$members, _data$bannerByPage;

  var host = _ref.host,
      isMobile = _ref.isMobile,
      pageType = _ref.pageType,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useBannerByPageQuery = useBannerByPageQuery(_objectSpread({
    variables: {
      input: {
        page: pageType
      }
    },
    ssr: true
  }, host !== "" ? {
    context: {
      headers: {
        host: host,
        "x-forwarded-host": host
      }
    }
  } : {})),
      data = _useBannerByPageQuery.data;

  var _useContext = useContext(CustomerContext),
      customerData = _useContext.data;

  var _useTheme = useTheme(),
      breakpoints = _useTheme.breakpoints;

  var isMigrationEligible = customerData === null || customerData === void 0 ? void 0 : (_customerData$members = customerData.membership) === null || _customerData$members === void 0 ? void 0 : _customerData$members.eligibleForMigration;
  var banners = data === null || data === void 0 ? void 0 : (_data$bannerByPage = data.bannerByPage) === null || _data$bannerByPage === void 0 ? void 0 : _data$bannerByPage.banners.filter(function (banner) {
    return banner.platforms.some(function (platform) {
      return platform === "DESKTOP" || platform === "MOBILE";
    });
  });

  if (!banners || isMigrationEligible) {
    return null;
  }

  var desktopBanners = banners === null || banners === void 0 ? void 0 : banners.filter(function (banner) {
    return banner.platforms.some(function (platform) {
      return platform === "DESKTOP";
    });
  });
  var mobileBanners = banners === null || banners === void 0 ? void 0 : banners.filter(function (banner) {
    return banner.platforms.some(function (platform) {
      return platform === "MOBILE";
    });
  });

  var renderBanners = function renderBanners(banners) {
    return ___EmotionJSX(React.Fragment, null, banners && banners.map(function (banner, index) {
      return ___EmotionJSX(Flex, _extends({
        key: banner.id,
        maxWidth: "1340px",
        mb: "30px",
        overflow: "hidden",
        px: "10px",
        onClick: function onClick() {
          return banner.image.url && emitBannerClick({
            id: 2,
            name: banner.image.url.substring(banner.image.url.lastIndexOf("/") + 1),
            targetUrl: banner.image.url,
            position: index + 1
          });
        },
        justifyContent: "center"
      }, props), ___EmotionJSX(Link, {
        href: banner.url
      }, ___EmotionJSX(ImageResponsive, {
        src: banner.image.url,
        alt: banner.image.altText || "promotional banner",
        maxHeight: "192px",
        maxWidth: breakpoints.xl,
        width: "auto",
        height: {
          xs: "calc((100vw - 20px) / ".concat(banner.image.width / banner.image.height, ")"),
          xl: "calc(".concat(breakpoints.xl, " / ").concat(banner.image.width / banner.image.height, ")")
        },
        lazyLoad: true,
        margin: "0 auto"
      })));
    }));
  };

  if (isMobile) {
    return renderBanners(mobileBanners);
  } else {
    return renderBanners(desktopBanners);
  }
};