import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _experimentsMap;

var commonExperiments = ["search_category_filters_apr23", "search_box_uplift", "onepass_migration_popup_may_23"];
export var experimentsMap = (_experimentsMap = {}, _defineProperty(_experimentsMap, "buy-now-app", ["buy_now_hide_category_nav", "buy_now_checkout_experiment_phase_1"].concat(commonExperiments)), _defineProperty(_experimentsMap, "cart-app", ["onepass_reward_sept22", "web_cart_seasonal_upsell_sep_23"].concat(commonExperiments)), _defineProperty(_experimentsMap, "header-app", [].concat(commonExperiments)), _defineProperty(_experimentsMap, "onepass-app", ["personalised_recommender_title_july_2022", "club_shop_recommender_components_jun21", "recently_viewed_recommender_title_july_2022", "onepass_hero_deal_revamp_nov_22"].concat(commonExperiments)), _defineProperty(_experimentsMap, "privacy-app", [].concat(commonExperiments)), _defineProperty(_experimentsMap, "product-app", ["web_xmas_widget_nov_22", "product_card_redesign_jul23", "incompatible_electronic_recommendations_june_22", "personalised_recommender_title_july_2022", "recently_viewed_recommender_title_july_2022", "similar_product_recommendation_experiment", "personalised_recommender_on_product_page_may_22", "retail_media_product_page_recommender_oct_23"].concat(commonExperiments)), _defineProperty(_experimentsMap, "search-app", ["retail_media_search_results_top_oct_23"].concat(commonExperiments)), _defineProperty(_experimentsMap, "shopfront-app", [].concat(commonExperiments)), _defineProperty(_experimentsMap, "fallback", [].concat(commonExperiments)), _experimentsMap);
var experimentKeys = Object.entries(experimentsMap).flatMap(function (_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      _key = _ref2[0],
      val = _ref2[1];

  return val;
});
export var appKeys = Object.keys(experimentsMap);