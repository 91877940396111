import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export function emitProductGroupImpression(_ref) {
  var source_type = _ref.source_type,
      product_count = _ref.product_count,
      displayed_group_price = _ref.displayed_group_price;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:au.com.catch/product_group_impression/jsonschema/1-0-2",
      data: {
        source_type: source_type || null,
        product_count: product_count,
        displayed_group_price: displayed_group_price
      }
    }
  });
}