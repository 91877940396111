import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// @ts-ignore

/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */

export var GetAnalyticsConfigurationDocument = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query GetAnalyticsConfiguration($domain: String) {\n  cmsConfiguration {\n    analytics {\n      gtm {\n        enabled\n        containerId\n      }\n      snowplow(input: {domain: $domain}) {\n        appId\n        eventMethod\n        platform\n        contexts {\n          webPage\n        }\n        collectorUrl\n        scriptSrc\n        cookieDomain\n        pageUnloadTimer\n      }\n    }\n  }\n}\n    "])));
/**
 * __useGetAnalyticsConfigurationQuery__
 *
 * To run a query within a React component, call `useGetAnalyticsConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticsConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticsConfigurationQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */

export function useGetAnalyticsConfigurationQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(GetAnalyticsConfigurationDocument, options);
}
export function useGetAnalyticsConfigurationLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(GetAnalyticsConfigurationDocument, options);
}
export var ExperimentsDocument = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query Experiments($input: ExperimentsInput!) {\n  experiments(input: $input) {\n    experiments {\n      id\n      name\n      experiment\n      bucket\n      variation\n      active\n    }\n  }\n}\n    "])));
/**
 * __useExperimentsQuery__
 *
 * To run a query within a React component, call `useExperimentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperimentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperimentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */

export function useExperimentsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(ExperimentsDocument, options);
}
export function useExperimentsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(ExperimentsDocument, options);
}