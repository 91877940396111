import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/** A local storage interface to fallback to inmemory storage in case of unavailable localStorage */
var isStorageAvailable = function isStorageAvailable() {
  var isLSAvailable;

  if (typeof isLSAvailable == "undefined") {
    try {
      var test = "test";
      window.localStorage.setItem(test, test);
      window.localStorage.removeItem(test);
      isLSAvailable = true;
    } catch (_unused) {
      isLSAvailable = false;
    }

    return isLSAvailable;
  } else {
    return isLSAvailable;
  }
};

export var localStorage = {
  data: {},
  setItem: function setItem(key, value) {
    if (isStorageAvailable()) {
      window.localStorage.setItem(key, value);
    } else {
      this.data = _objectSpread(_objectSpread({}, this.data), {}, _defineProperty({}, key, value));
    }
  },

  /**
   * @param {number} ttl - Time to live in seconds.
   */
  setItemWithExpiry: function setItemWithExpiry(key, value, ttl) {
    var now = new Date();
    var item = {
      value: value,
      expiry: now.getTime() + ttl * 1000
    };
    localStorage.setItem(key, JSON.stringify(item));
  },
  getItem: function getItem(key) {
    if (isStorageAvailable()) {
      return window.localStorage.getItem(key);
    } else {
      return this.data[key];
    }
  },
  getItemWithExpiry: function getItemWithExpiry(key) {
    var itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    var item = JSON.parse(itemStr);
    var now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  },
  removeItem: function removeItem(key) {
    if (isStorageAvailable()) {
      window.localStorage.removeItem(key);
    } else {
      delete this.data[key];
    }
  },
  clear: function clear() {
    if (isStorageAvailable()) {
      window.localStorage.clear();
    } else {
      this.data = {};
    }
  }
};
export var sessionStorage = {
  data: {},
  setItem: function setItem(key, value) {
    if (isStorageAvailable()) {
      window.sessionStorage.setItem(key, value);
    } else {
      this.data = _objectSpread(_objectSpread({}, this.data), {}, _defineProperty({}, key, value));
    }
  },
  getItem: function getItem(key) {
    if (isStorageAvailable()) {
      return window.sessionStorage.getItem(key);
    } else {
      return this.data[key];
    }
  },
  removeItem: function removeItem(key) {
    if (isStorageAvailable()) {
      window.sessionStorage.removeItem(key);
    } else {
      delete this.data[key];
    }
  },
  clear: function clear() {
    if (isStorageAvailable()) {
      window.sessionStorage.clear();
    } else {
      this.data = {};
    }
  }
};