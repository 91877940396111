import { ENV_CONFIG_PRIVATE_KEY, ENV_CONFIG_PUBLIC_KEY } from "../internal/store.js";
import { mapWithoutPublicSuffix } from "../internal/utils.js";
/**
 * Gets the Environment Config for the current environment.
 * 💁 Will return the Private Environment Config for the server..
 *
 * @example ```
 * // pages/index.tsx
 * import { getEnvConfig } from "@catchoftheday/env-config";
 *
 * export default function Home() {
 *   const { FOO } = getEnvConfig();
 *
 *   return <div>{FOO}</div>
 * }
 */

export function getEnvConfig() {
  if (true) {
    // 🧑‍💻 Client-side
    if (ENV_CONFIG_PUBLIC_KEY in globalThis && globalThis[ENV_CONFIG_PUBLIC_KEY]) return getClientEnvConfig();
  } else {
    // ☁️ Server-side
    // 🗒️ Keep wrapped in `else` to ensure our tests don't use as fallback.
    if (ENV_CONFIG_PRIVATE_KEY in globalThis && globalThis[ENV_CONFIG_PRIVATE_KEY]) return globalThis[ENV_CONFIG_PRIVATE_KEY];
  }

  throw new Error("Env config not initialised. Have you wrapped your app with `withEnvConfig`?");
}
/**
 * Gets the Public Environment Config for the current environment.
 * 💁 Will return the Public Environment Config in the browser.
 *
 * @example ```
 * // pages/index.tsx
 * import { getEnvConfig } from "@catchoftheday/env-config";
 *
 * export default function Home() {
 *   const envConfig = getEnvConfig();
 *
 *   return <div>{envConfig.foo}</div>
 * }
 */

export function getPublicEnvConfig() {
  if (ENV_CONFIG_PUBLIC_KEY in globalThis && globalThis[ENV_CONFIG_PUBLIC_KEY]) return globalThis[ENV_CONFIG_PUBLIC_KEY];
  throw new Error("Env config not initialised. Have you wrapped your app with `withEnvConfig`?");
} //#region Private Functions

var clientEnvConfig = null;
/**
 * Maps the Public Environment Config to the Private Environment Config without the `_PUBLIC` suffix.
 * Wraps with a Proxy to throw an error if attempting to access a private variable
 * (i.e. it's missing from the public config, and therefore missing from the client).
 */

function getClientEnvConfig() {
  if (clientEnvConfig === null) {
    var publicEnvConfig = mapWithoutPublicSuffix(globalThis[ENV_CONFIG_PUBLIC_KEY]);
    clientEnvConfig = new Proxy(publicEnvConfig, {
      get: function get(target, prop) {
        if (prop in target) return target[prop]; // If attempting access a private variable (i.e. it's missing from the public config), throw an error.

        throw new Error("Cannot access private environment config in the browser.");
      }
    }); // Cast as we'll throw runtime errors if accessed incorrectly.
  }

  return clientEnvConfig;
} //#endregion