import React from "react";
import Head from "next/head";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Polyfills = function Polyfills() {
  return ___EmotionJSX(Head, null, ___EmotionJSX("link", {
    rel: "preload",
    as: "script",
    href: "https://polyfill.io/v3/polyfill.min.js?features=es6%2CNodeList.prototype.%40%40iterator%2CPromise%2CElement.prototype.closest%2CPromise.prototype.finally%2CglobalThis%2CObject.values%2CObject.entries%2CObject.fromEntries%2CObject.assign%2ClocalStorage%2CArray.prototype.includes%2CArray.prototype.flatMap%2CArray.prototype.flat%2CArray.prototype.find%2CString.prototype.includes%2CString.prototype.startsWith%2CSymbol.toStringTag%2CNumber.isNaN%2Cfetch%2CElement.prototype.prepend%2Csmoothscroll%2CIntersectionObserver&flags=gated",
    crossOrigin: "anonymous"
  }), ___EmotionJSX("script", {
    crossOrigin: "anonymous",
    src: "https://polyfill.io/v3/polyfill.min.js?features=es6%2CNodeList.prototype.%40%40iterator%2CPromise%2CElement.prototype.closest%2CPromise.prototype.finally%2CglobalThis%2CObject.values%2CObject.entries%2CObject.fromEntries%2CObject.assign%2ClocalStorage%2CArray.prototype.includes%2CArray.prototype.flatMap%2CArray.prototype.flat%2CArray.prototype.find%2CString.prototype.includes%2CString.prototype.startsWith%2CSymbol.toStringTag%2CNumber.isNaN%2Cfetch%2CElement.prototype.prepend%2Csmoothscroll%2CIntersectionObserver&flags=gated"
  }));
};