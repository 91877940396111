import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["Component", "pageProps"];
import React from "react";
import { CART_APOLLO_CACHE_CONFIG } from "@catchoftheday/cart-components";
import { PageContainer } from "@catchoftheday/cg-components";
import { nextAppHOC } from "@catchoftheday/common-vendors";
import { getEnvConfig, withEnvConfig } from "@catchoftheday/env-config";
import { ThemeProvider } from "@catchoftheday/theme";
import { isClientError } from "@catchoftheday/utilities";
import { RETRIABLE_OPERATIONS } from "@product-app/config";
import { jsx as ___EmotionJSX } from "@emotion/react";

function ProductApp(_ref) {
  var Component = _ref.Component,
      pageProps = _ref.pageProps,
      rest = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(ThemeProvider, null, ___EmotionJSX(PageContainer, null, ___EmotionJSX(Component, _extends({}, pageProps, rest))));
}

export default withEnvConfig(nextAppHOC({
  graphqlEndpoint: function graphqlEndpoint() {
    return getEnvConfig().GRAPHQL_ENDPOINT;
  },
  apolloCacheConfig: CART_APOLLO_CACHE_CONFIG,
  fonts: ["biennale", "montserrat"],
  // eslint-disable-next-line n/no-process-env
  connectToDevTools: false,
  seo: {
    canonicalTag: {
      disabled: true
    },
    alternateTags: {
      disabled: true
    }
  },
  retryOptions: function retryOptions() {
    return {
      attempts: {
        retryIf: function retryIf(error, operation) {
          return !!error && error.statusCode && !isClientError(error.statusCode) && RETRIABLE_OPERATIONS.includes(operation.operationName);
        }
      },
      retryTimeout: getEnvConfig().APOLLO_RETRY_TIMEOUT
    };
  }
})(ProductApp));