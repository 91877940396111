import { isMobileUA } from "@catchoftheday/utilities";
var KUBE_PROBE_UA = "kube-probe";
export var hostMap = {
  "www.catch.com.au": "m.catch.com.au",
  "www.catch.co.nz": "m.catch.co.nz",
  "catch.com.au": "m.catch.com.au",
  "catch.co.nz": "m.catch.co.nz",
  "cotdweb.devcat.ch": "cotdmob.devcat.ch",
  "canzweb.devcat.ch": "canzmob.devcat.ch"
};
export var redirectBasedOnUA = function redirectBasedOnUA(req, res) {
  var host = req.headers["x-forwarded-host"] || req.headers.host;
  var protocol = req.headers["x-forwarded-proto"] || "https";
  var ua = req.headers["user-agent"]; // If we can't determine the host or user agent,
  // or if the request is from a Kube probe, don't proceed any further.

  if (!host || !ua || ua !== null && ua !== void 0 && ua.includes(KUBE_PROBE_UA)) return;
  var mobileHosts = Object.values(hostMap);
  var desktopHosts = Object.keys(hostMap);
  var isMobileDevice = isMobileUA(ua);

  if (isMobileDevice && desktopHosts.includes(host)) {
    var mobileHost = hostMap[host];

    if (mobileHost) {
      res.writeHead(307, {
        Location: "".concat(protocol, "://").concat(mobileHost).concat(req.url)
      });
      res.end();
      return;
    }
  }

  if (!isMobileDevice && mobileHosts.includes(host)) {
    var desktopHost = desktopHosts[mobileHosts.indexOf(host)];

    if (desktopHost) {
      res.writeHead(307, {
        Location: "".concat(protocol, "://").concat(desktopHost).concat(req.url)
      });
      res.end();
      return;
    }
  }
};