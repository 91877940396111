import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import { color, compose, css, hoverColorProp, hoverTextDecorationProp, layout, position, space, textDecorationProp, textTransformProp, themeGet, typography } from "@catchoftheday/theme";
var styleProps = compose(color, space, typography, layout, position, textTransformProp, textDecorationProp);
/**
 * Styles for link (rounded or not)
 */

export function linkStyles(props) {
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    color: ", ";\n    text-decoration: none;\n    cursor: pointer;\n\n    &:hover,\n    &:focus {\n      ", "\n      > span, p, svg {\n        ", "\n      }\n      ", "\n    }\n\n    &:focus-visible {\n      outline: 1px solid ", ";\n      outline-offset: 2px;\n    }\n\n    &:focus[data-focus-visible-added] {\n      outline: 1px solid ", ";\n      outline-offset: 2px;\n    }\n\n    ", "\n  "])), themeGet("colors.typography.link")(props), hoverColorProp(props), hoverColorProp(props), hoverTextDecorationProp(props), themeGet("colors.secondaryDark")(props), themeGet("colors.secondaryDark")(props), styleProps(props));
}