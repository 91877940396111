import { useContext, useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { CustomerContext } from "@catchoftheday/customer";
import { embeddedConfig, getEnvConfig } from "@catchoftheday/env-config";
import { dobToAgeRange } from "@catchoftheday/utilities";
export var DataDogRUM = function DataDogRUM() {
  var _getEnvConfig = getEnvConfig(),
      ENV_NAME = _getEnvConfig.ENV_NAME,
      DD_RUM_SAMPLE_RATE = _getEnvConfig.DD_RUM_SAMPLE_RATE,
      DD_RUM_PREMIUM_SAMPLE_RATE = _getEnvConfig.DD_RUM_PREMIUM_SAMPLE_RATE;

  var _useContext = useContext(CustomerContext),
      customer = _useContext.data;

  useEffect(function () {
    var viewName = window.location.pathname.split("/").slice(1)[0];
    datadogRum.init({
      applicationId: "886ddfab-d681-4475-89de-30d1f5c04831",
      // TODO: Should this be hardcoded?
      clientToken: "pub10536218d0da686ff832428a61ff4c56",
      // TODO: Should this be hardcoded?
      site: "datadoghq.com",
      service: embeddedConfig.APP_NAME,
      env: ENV_NAME,
      sampleRate: Number(DD_RUM_SAMPLE_RATE) || 0,
      premiumSampleRate: Number(DD_RUM_PREMIUM_SAMPLE_RATE) || 0,
      trackUserInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
      trackSessionAcrossSubdomains: true,
      enableExperimentalFeatures: ["clickmap"],
      trackFrustrations: true,
      trackLongTasks: true,
      trackViewsManually: true,
      version: embeddedConfig.VERSION
    });
    datadogRum.startSessionReplayRecording();
    datadogRum.startView({
      name: viewName === "" ? "homepage" : viewName
    });
  }, [ENV_NAME, DD_RUM_SAMPLE_RATE, DD_RUM_PREMIUM_SAMPLE_RATE]);
  useEffect(function () {
    if (customer) {
      var _membership$subscript;

      var id = customer.id,
          membership = customer.membership,
          dob = customer.dob,
          gender = customer.gender;
      datadogRum.setUser({
        id: id,
        isOnepassMember: membership === null || membership === void 0 ? void 0 : (_membership$subscript = membership.subscription) === null || _membership$subscript === void 0 ? void 0 : _membership$subscript.active,
        ageRange: dobToAgeRange(dob),
        gender: gender
      });
    }
  }, [customer]);
  return null;
};