import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["shouldTrackVisibility", "onComponentSeen", "threshold", "triggerOnce"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
export var useIsInViewport = function useIsInViewport(props) {
  var _ref = props || {},
      _ref$shouldTrackVisib = _ref.shouldTrackVisibility,
      shouldTrackVisibility = _ref$shouldTrackVisib === void 0 ? true : _ref$shouldTrackVisib,
      onComponentSeen = _ref.onComponentSeen,
      _ref$threshold = _ref.threshold,
      threshold = _ref$threshold === void 0 ? 1 : _ref$threshold,
      _ref$triggerOnce = _ref.triggerOnce,
      triggerOnce = _ref$triggerOnce === void 0 ? true : _ref$triggerOnce,
      otherProps = _objectWithoutProperties(_ref, _excluded);

  var _useInView = useInView(_objectSpread({
    threshold: threshold,
    triggerOnce: triggerOnce
  }, otherProps)),
      ref = _useInView.ref,
      inView = _useInView.inView;

  var wasComponentSeen = useRef(false);
  useEffect(function () {
    if (typeof onComponentSeen === "function" && inView && shouldTrackVisibility && !wasComponentSeen.current) {
      wasComponentSeen.current = true;
      onComponentSeen();
    } else if (typeof onComponentSeen === "function" && !inView && shouldTrackVisibility && wasComponentSeen.current && !triggerOnce) {
      wasComponentSeen.current = false;
    }
  }, [inView, onComponentSeen, shouldTrackVisibility, wasComponentSeen.current, triggerOnce]);
  return {
    ref: ref,
    inView: inView
  };
};