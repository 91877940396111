export function encodeSearchTerm() {
  var term = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var termArray = term.split(" ");

  if (termArray.length == 1) {
    return encodeURI(term);
  } else {
    return termArray.map(function (term) {
      return encodeURIComponent(term);
    }).join("+");
  }
}