import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import memoize from "lodash.memoize";
import { stripUnit } from "./polished.js";

var getValueAndUnit = function getValueAndUnit(value) {
  return stripUnit(value, true);
};

var ONE_PX_IN_REM = 1 / 16;
export var minusOnePx = memoize(function (value, convertToPx) {
  var _getValueAndUnit = getValueAndUnit(value),
      _getValueAndUnit2 = _slicedToArray(_getValueAndUnit, 2),
      dimension = _getValueAndUnit2[0],
      unit = _getValueAndUnit2[1];

  switch (unit) {
    case "em":
    case "rem":
      return "".concat(convertToPx ? dimension / ONE_PX_IN_REM - 1 : dimension - ONE_PX_IN_REM).concat(convertToPx ? "px" : unit);

    case "px":
      return "".concat(dimension - 1, "px");

    default:
      return value;
  }
});