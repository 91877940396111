import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { ContentPlaceHolder } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Placeholder = function Placeholder(props) {
  return ___EmotionJSX(ContentPlaceHolder, _extends({
    gradientRatio: 0.9,
    backgroundColor: "#f3f3f3",
    foregroundColor: "#ecebeb"
  }, props));
};