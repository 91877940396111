import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["children", "appElement", "onRequestClose", "shouldCloseOnEsc", "shouldCloseOnOverlayClick", "shouldDisableBodyScrolling", "showCloseButton", "onClose", "closeButtonProps", "closeIconProps", "contentProps", "enableDefaultResponsiveStyles"],
    _excluded2 = ["appElement", "className"];

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import React, { useEffect } from "react";
import ReactModal from "react-modal";
import { border, compose, css, flexbox, Global, layout, space, styled, themeGet } from "@catchoftheday/theme";
import { Box } from "../Box";
import { Button } from "../Button";
import { IconCross } from "../Icon";
import { sizeVariant } from "./sizeVariant";
import { jsx as ___EmotionJSX } from "@emotion/react";
var StyledCloseButton = styled(Button)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-radius: 0 0 50px 50px;\n\n  &:hover:not(:disabled):not([disabled]) {\n    border-radius: 0 0 50px 50px;\n  }\n"])));
var styledProps = compose(layout, flexbox, space, border);
export var Modal = function Modal(_ref) {
  var children = _ref.children,
      appElement = _ref.appElement,
      onRequestClose = _ref.onRequestClose,
      _ref$shouldCloseOnEsc = _ref.shouldCloseOnEsc,
      shouldCloseOnEsc = _ref$shouldCloseOnEsc === void 0 ? true : _ref$shouldCloseOnEsc,
      _ref$shouldCloseOnOve = _ref.shouldCloseOnOverlayClick,
      shouldCloseOnOverlayClick = _ref$shouldCloseOnOve === void 0 ? true : _ref$shouldCloseOnOve,
      _ref$shouldDisableBod = _ref.shouldDisableBodyScrolling,
      shouldDisableBodyScrolling = _ref$shouldDisableBod === void 0 ? true : _ref$shouldDisableBod,
      _ref$showCloseButton = _ref.showCloseButton,
      showCloseButton = _ref$showCloseButton === void 0 ? true : _ref$showCloseButton,
      onClose = _ref.onClose,
      closeButtonProps = _ref.closeButtonProps,
      closeIconProps = _ref.closeIconProps,
      contentProps = _ref.contentProps,
      _ref$enableDefaultRes = _ref.enableDefaultResponsiveStyles,
      enableDefaultResponsiveStyles = _ref$enableDefaultRes === void 0 ? true : _ref$enableDefaultRes,
      props = _objectWithoutProperties(_ref, _excluded);

  var onCloseHandler = onClose || onRequestClose;
  return ___EmotionJSX(StyledReactModal, _extends({
    appElement: appElement,
    enableDefaultResponsiveStyles: enableDefaultResponsiveStyles,
    onRequestClose: onRequestClose,
    shouldCloseOnEsc: shouldCloseOnEsc,
    shouldCloseOnOverlayClick: shouldCloseOnOverlayClick,
    mx: 3
  }, props), shouldDisableBodyScrolling && ___EmotionJSX(Global, {
    styles: css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n            .ReactModal__Body--open {\n              overflow: hidden;\n            }\n          "])))
  }), showCloseButton && ___EmotionJSX(Box, {
    position: "absolute",
    top: "-2px",
    right: "18px",
    zIndex: 1
  }, ___EmotionJSX(StyledCloseButton, _extends({
    variant: "plain",
    sizeVariant: "xs",
    display: "inline",
    p: "5px",
    pt: "0px",
    width: "28px",
    height: "28px",
    backgroundColor: "black",
    color: "white",
    onClick: onCloseHandler,
    "aria-label": "Close"
  }, closeButtonProps), ___EmotionJSX(IconCross, _extends({
    height: "12px",
    width: "12px",
    title: "Close"
  }, closeIconProps)))), ___EmotionJSX(Box, _extends({
    px: {
      xs: 6,
      md: 8
    },
    py: 8,
    fontWeight: "light",
    fontSize: "sm"
  }, contentProps), children));
};

var StylableReactModal = function StylableReactModal(_ref2) {
  var _ref2$appElement = _ref2.appElement,
      appElement = _ref2$appElement === void 0 ? "body" : _ref2$appElement,
      className = _ref2.className,
      props = _objectWithoutProperties(_ref2, _excluded2);

  useEffect(function () {
    ReactModal.setAppElement(appElement);
  }, [appElement]);
  return ___EmotionJSX(ReactModal, _extends({
    overlayClassName: "ModalOverlay",
    className: "ModalContent",
    portalClassName: className
  }, props));
};

var StyledReactModal = styled(StylableReactModal)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  .ModalOverlay {\n    position: fixed;\n    top: 0px;\n    left: 0px;\n    right: 0px;\n    bottom: 0px;\n    background-color: ", ";\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: ", ";\n  }\n\n  .ModalContent {\n    position: relative;\n    border: none;\n    background: white;\n    overflow: auto;\n    outline: none;\n    padding: 0px;\n    border-radius: ", ";\n\n    ", "\n\n    @media screen and (min-width: ", ") {\n      ", "\n    }\n\n    @media screen and (min-width: ", ") {\n      ", "\n    }\n    ", "\n\n    ", "\n  }\n"])), themeGet("colors.ui.modalOverlay"), function (props) {
  return themeGet("zIndices.".concat(props.zIndex || 5));
}, themeGet("radii.corner"), function (props) {
  return props.enableDefaultResponsiveStyles ? css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n            max-height: 85%;\n            height: 85%;\n            width: 85%;\n          "]))) : "";
}, themeGet("breakpoints.xs"), function (props) {
  return props.enableDefaultResponsiveStyles ? css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n              max-height: 100%;\n              height: 100%;\n              width: 100%;\n            "]))) : "";
}, themeGet("breakpoints.md"), function (props) {
  return props.enableDefaultResponsiveStyles ? css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n              height: auto;\n              height: initial; /* set to be 'initial' if the browser supports, if not set it as 'auto'  */\n              width: 50%;\n            "]))) : "";
}, sizeVariant, styledProps);
Modal.displayName = "Modal";
export default Modal;