import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var cssRegex = /^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/; // eslint-disable-next-line @typescript-eslint/no-explicit-any

export function stripUnit(value, unitReturn) {
  if (typeof value !== "string") return unitReturn ? [value, undefined] : value;
  var matchedValue = value.match(cssRegex);

  if (unitReturn) {
    if (matchedValue) return [parseFloat(value), matchedValue[2]];
    return [value, undefined];
  }

  if (matchedValue) return parseFloat(value);
  return value;
}
export var rem = function rem(val) {
  var baseFontSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;

  var _stripUnit = stripUnit(val, true),
      _stripUnit2 = _slicedToArray(_stripUnit, 2),
      dimension = _stripUnit2[0],
      unit = _stripUnit2[1];

  if (unit != "px") {
    throw new Error("Please provide px unit");
  }

  return "".concat(dimension / baseFontSize, "rem");
};
export var hideVisually = function hideVisually() {
  return "\n   border: 0;\n   clip: rect(0 0 0 0);\n   height: 1px;\n   margin: -1px;\n   overflow: hidden;\n   padding: 0;\n   position: absolute;\n   white-space: nowrap;\n   width: 1px;\n";
};