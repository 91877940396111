import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
import { isAndroidHybridApp } from "../isAndroidWebView/isAndroidWebView.js";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function hideOnAndroidWebView(Comp) {
  return function HideOnAndroidWebView(props) {
    var _React$useState = React.useState(true),
        _React$useState2 = _slicedToArray(_React$useState, 2),
        show = _React$useState2[0],
        setShow = _React$useState2[1];

    React.useEffect(function () {
      setShow(!isAndroidHybridApp());
    }, []);

    if (!show) {
      return null;
    }

    return ___EmotionJSX(Comp, props);
  };
}