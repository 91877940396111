import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["retryTimeout", "attempts"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { getDataFromTree } from "@apollo/client/react/ssr";
import ApolloLinkTimeout from "apollo-link-timeout";
import { withApollo as nextWithApollo } from "next-with-apollo";
import { embeddedConfig } from "@catchoftheday/env-config";
import { isClientError } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function initClient(_ref) {
  var initialState = _ref.initialState,
      uri = _ref.uri,
      ssrOrigin = _ref.ssrOrigin,
      cacheConfig = _ref.cacheConfig,
      defaultOptions = _ref.defaultOptions,
      connectToDevTools = _ref.connectToDevTools,
      lazyRetryOptions = _ref.retryOptions;

  var apolloLink = function apolloLink() {
    var retryOptions = typeof lazyRetryOptions === "function" ? lazyRetryOptions() : lazyRetryOptions;

    if (!retryOptions) {
      // If no retryOptions are configured, provide the uri
      return {
        uri: uri
      };
    }

    var retryTimeout = retryOptions.retryTimeout,
        attempts = retryOptions.attempts,
        otherOptions = _objectWithoutProperties(retryOptions, _excluded);

    var timeoutLink = new ApolloLinkTimeout(retryTimeout);
    var errorLink = onError(function (_ref2) {
      var networkError = _ref2.networkError;

      if (networkError && networkError.message === "Timeout exceeded") {
        // There's no elegant way to ignore specific network errors in Apollo Client v3
        // Implementing this workaround: https://github.com/apollographql/apollo-feature-requests/issues/153#issuecomment-513148734
        // Unset error message if the request has timed out in the timeoutLink so that it can be ignored in Sentry
        networkError.message = "";
      }
    });
    var httpLink = createHttpLink({
      uri: uri
    });
    var retryLink = new RetryLink(_objectSpread({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true
      },
      attempts: _objectSpread({
        max: 3,
        retryIf: function retryIf(error) {
          return !!error && !isClientError(error.statusCode);
        }
      }, attempts || {})
    }, otherOptions));
    return {
      link: from([retryLink, errorLink, timeoutLink, httpLink])
    };
  };

  return new ApolloClient(_objectSpread(_objectSpread({
    name: embeddedConfig.APP_NAME,
    version: embeddedConfig.VERSION,
    cache: new InMemoryCache(cacheConfig).restore(initialState),
    headers: _objectSpread(_objectSpread({
      Accept: "application/json"
    }, ssrOrigin ? {
      "x-forwarded-host": ssrOrigin
    } : undefined), ssrOrigin ? {
      "x-ssr-origin": ssrOrigin
    } : undefined)
  }, defaultOptions && {
    defaultOptions: defaultOptions
  }), {}, {
    connectToDevTools: !!connectToDevTools
  }, apolloLink()));
}
/**
 * Wraps a NextJS page with an Apollo provider and handles SSR data fetching
 */

export var withApollo = function withApollo(_ref3) {
  var uri = _ref3.uri,
      cacheConfig = _ref3.cacheConfig,
      defaultOptions = _ref3.defaultOptions,
      connectToDevTools = _ref3.connectToDevTools,
      retryOptions = _ref3.retryOptions;
  return nextWithApollo(function (_ref4) {
    var _ctx$req, _ctx$req2;

    var initialState = _ref4.initialState,
        ctx = _ref4.ctx;
    var isLazyUri = typeof uri === "function";
    var isLegacyUri = true && !isLazyUri;

    var _uri = isLazyUri ? uri() : uri; // Allow uri to be fetched lazily


    var finalUri = isLegacyUri ? "/api" : _uri; // During the migration we need to support both the old and new uri styles.

    return initClient({
      initialState: initialState,
      uri: finalUri,
      ssrOrigin: (ctx === null || ctx === void 0 ? void 0 : (_ctx$req = ctx.req) === null || _ctx$req === void 0 ? void 0 : _ctx$req.headers.host) || (ctx === null || ctx === void 0 ? void 0 : (_ctx$req2 = ctx.req) === null || _ctx$req2 === void 0 ? void 0 : _ctx$req2.headers["x-forwarded-host"]),
      cacheConfig: cacheConfig,
      defaultOptions: defaultOptions,
      connectToDevTools: connectToDevTools,
      retryOptions: retryOptions
    });
  }, {
    getDataFromTree: getDataFromTree,
    render: function NextWithApolloWrapper(_ref5) {
      var Page = _ref5.Page,
          props = _ref5.props;
      return ___EmotionJSX(ApolloProvider, {
        client: props.apollo
      }, ___EmotionJSX(Page, props));
    }
  });
};