import React, { useContext } from "react";
import { HtmlContext } from "next/dist/shared/lib/html-context.js";
import { getEnvConfig } from "../public/get.js";
/**
 * Wraps a Next document with HtmlContext with assetPrefix set in the runtime
 * Reference: https://github.com/vercel/next.js/discussions/38142#discussioncomment-3128588
 */

import { jsx as ___EmotionJSX } from "@emotion/react";
export function NextEnvContext(_ref) {
  var children = _ref.children;
  var context = useContext(HtmlContext);
  if (context) context.assetPrefix = getEnvConfig().STATIC_URL;
  return ___EmotionJSX(HtmlContext.Provider, {
    value: context
  }, children);
}