import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _toConsumableArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";

var _templateObject;

import { createShouldForwardProp, props } from "@styled-system/should-forward-prop";
import { borders, color, compose, flexbox, layout, position, shadow, space, styled, system, textTransformProp, theme, typography } from "@catchoftheday/theme";
import { sizeVariant } from "./styles/sizeVariant";
import { styleVariant } from "./styles/styleVariant";
var shouldForwardProp = createShouldForwardProp([].concat(_toConsumableArray(props), ["color", "sizeVariant", "variant", "outline", "block", "textTransform", "hoverBorderColor"]));
var whiteSpace = system({
  whiteSpace: {
    property: "whiteSpace"
  }
});
var styledProps = compose(space, color, layout, position, borders, flexbox, whiteSpace, typography, textTransformProp, shadow);
export var Button = styled("button", {
  shouldForwardProp: shouldForwardProp
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  ", "\n\n  ", "\n"])), sizeVariant, styleVariant, styledProps);
Button.displayName = "Button";
Button.defaultProps = {
  variant: "primary",
  sizeVariant: "md",
  block: false,
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  focusOutline: "1px solid ".concat(theme.colors.secondary)
};
export default Button;