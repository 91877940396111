import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";

var _templateObject;

var _excluded = ["nextAs"];
import React from "react";
import BaseNextLink from "next/link";
import { createDefaultShouldForwardProp, styled } from "@catchoftheday/theme";
import { linkStyles } from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";

/**
 * Allow Next's Link props
 * https://nextjs.org/docs/pages/api-reference/components/link
 */
var shouldForwardProp = createDefaultShouldForwardProp(["replace", "scroll", "prefetch", "legacyBehavior", "passHref", "scroll", "shallow", "locale",
/**
 * this is Next's Link `as` prop being renamed
 * (to avoid conflict with Emotion's `as` prop)
 */
"nextAs"]);

var BaseLink = function BaseLink(props) {
  var nextAs = props.nextAs,
      rest = _objectWithoutProperties(props, _excluded);

  return ___EmotionJSX(BaseNextLink, _extends({
    as: nextAs
  }, rest));
};

export var NextLink = styled(BaseLink, {
  shouldForwardProp: shouldForwardProp
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), linkStyles);
NextLink.displayName = "NextLink";
NextLink.defaultProps = {
  hoverColor: "typography.linkHover",
  hoverTextDecoration: "underline"
};
export default NextLink;