import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { getAlternateUrl, getCanonicalUrl, parseQueryString, serializeQueryString } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";

function getCanonicalQueryParams(queryString, config) {
  var queryObject = parseQueryString(queryString);
  var modifiedQueryObject = Object.entries(config).reduce(function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    if (typeof value === "boolean") {
      acc[key] = queryObject[key];
    } else if (typeof value === "string") {
      acc[key] = value;
    } else if (typeof value === "function") {
      acc[key] = value({
        location: location,
        value: queryObject[key]
      });
    }

    return acc;
  }, {});
  var queryParamsString = serializeQueryString(modifiedQueryObject);
  return queryParamsString ? "?".concat(queryParamsString) : "";
}

export var SEO = function SEO(_ref3) {
  var _ref3$ssr = _ref3.ssr,
      ssr = _ref3$ssr === void 0 ? true : _ref3$ssr,
      origin = _ref3.origin,
      alternateTags = _ref3.alternateTags,
      canonicalTag = _ref3.canonicalTag;

  var _useRouter = useRouter(),
      asPath = _useRouter.asPath;

  if (!ssr && false) {
    return null;
  }

  var parsedUrl = new URL("".concat(origin).concat(asPath));
  var canonicalUrl;

  if (!(canonicalTag !== null && canonicalTag !== void 0 && canonicalTag.disabled)) {
    var _canonicalTag$useTrai, _canonicalTag$useTrai2;

    var canonicalQueryParams = getCanonicalQueryParams(parsedUrl.search, (canonicalTag === null || canonicalTag === void 0 ? void 0 : canonicalTag.queryParams) || {});

    if (canonicalTag !== null && canonicalTag !== void 0 && (_canonicalTag$useTrai = canonicalTag.useTrailingSlash) !== null && _canonicalTag$useTrai !== void 0 && _canonicalTag$useTrai.call(canonicalTag, {
      url: parsedUrl
    }) && !parsedUrl.pathname.match(/\/$/)) {
      parsedUrl.pathname = "".concat(parsedUrl.pathname, "/");
    }

    if (!(canonicalTag !== null && canonicalTag !== void 0 && (_canonicalTag$useTrai2 = canonicalTag.useTrailingSlash) !== null && _canonicalTag$useTrai2 !== void 0 && _canonicalTag$useTrai2.call(canonicalTag, {
      url: parsedUrl
    })) && parsedUrl.pathname.match(/\/$/)) {
      parsedUrl.pathname = parsedUrl.pathname.replace(/\/$/, "");
    }

    canonicalUrl = "".concat(parsedUrl.origin).concat(parsedUrl.pathname).concat(canonicalQueryParams);
  }

  var alternateUrl;

  if (!(alternateTags !== null && alternateTags !== void 0 && alternateTags.disabled)) {
    var _alternateTags$includ;

    var includeQueryParams = alternateTags === null || alternateTags === void 0 ? void 0 : (_alternateTags$includ = alternateTags.includeQueryParams) === null || _alternateTags$includ === void 0 ? void 0 : _alternateTags$includ.call(alternateTags, {
      url: parsedUrl
    });
    alternateUrl = "".concat(parsedUrl.origin).concat(parsedUrl.pathname).concat(includeQueryParams ? parsedUrl.search : "");
  }

  return ___EmotionJSX(Head, null, canonicalUrl && ___EmotionJSX("link", {
    key: "canonical",
    rel: "canonical",
    href: getCanonicalUrl(canonicalUrl)
  }), alternateUrl && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("link", {
    key: "alternate-nz",
    rel: "alternate",
    href: getAlternateUrl(alternateUrl, "nz"),
    hrefLang: "en-NZ"
  }), ___EmotionJSX("link", {
    key: "alternate-au",
    rel: "alternate",
    href: getAlternateUrl(alternateUrl, "au"),
    hrefLang: "en-AU"
  }), ___EmotionJSX("link", {
    key: "alternate-mobile",
    rel: "alternate",
    media: "only screen and (max-width: 640px)",
    href: getAlternateUrl(alternateUrl, "mobile")
  })), ___EmotionJSX("meta", {
    name: "viewport",
    content: "width=device-width, initial-scale=1, maximum-scale=1"
  }), ___EmotionJSX("meta", {
    property: "fb:app_id",
    content: "282892088568315"
  }), ___EmotionJSX("meta", {
    property: "fb:page_id",
    content: "90383230869"
  }), ___EmotionJSX("script", {
    src: "https://cdn.bc0a.com/autopilot/f00000000244369/autopilot_sdk.js"
  }));
};