import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import shouldForwardProp from "@styled-system/should-forward-prop";
import { styled } from "@catchoftheday/theme";
import { linkStyles } from "./styles";
export var Link = styled("a", {
  shouldForwardProp: shouldForwardProp
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), linkStyles);
Link.displayName = "Link";
Link.defaultProps = {
  hoverColor: "typography.linkHover",
  hoverTextDecoration: "underline"
};
export default Link;