import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["as", "variant", "sizeVariant", "block", "textTransform", "hoverColor", "whiteSpace"];

var _templateObject, _templateObject2;

import React from "react";
import { hoverColorProp, styled } from "@catchoftheday/theme";
import { NextLink } from "../Link/NextLink";
import { Button } from "./Button";
import { jsx as ___EmotionJSX } from "@emotion/react";
var styles = "\ndisplay: inline-block;\n&:hover,\n&:focus {\n  text-decoration: none;\n  ".concat(hoverColorProp, "\n}\n&[disabled] {\n  opacity: 0.7;\n  cursor: not-allowed;\n}\n");
var AnchorLinkButton = styled(Button.withComponent("a", process.env.NODE_ENV === "production" ? {
  target: "eouzdxi0"
} : {
  target: "eouzdxi0",
  label: "AnchorLinkButton"
}))(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), styles);
var NextLinkButton = styled(Button.withComponent(NextLink, process.env.NODE_ENV === "production" ? {
  target: "eouzdxi1"
} : {
  target: "eouzdxi1",
  label: "NextLinkButton"
}))(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), styles);
export var LinkButton = function LinkButton(_ref) {
  var _ref$as = _ref.as,
      as = _ref$as === void 0 ? "a" : _ref$as,
      _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? "primary" : _ref$variant,
      _ref$sizeVariant = _ref.sizeVariant,
      sizeVariant = _ref$sizeVariant === void 0 ? "md" : _ref$sizeVariant,
      _ref$block = _ref.block,
      block = _ref$block === void 0 ? true : _ref$block,
      _ref$textTransform = _ref.textTransform,
      textTransform = _ref$textTransform === void 0 ? "uppercase" : _ref$textTransform,
      _ref$hoverColor = _ref.hoverColor,
      hoverColor = _ref$hoverColor === void 0 ? "white" : _ref$hoverColor,
      _ref$whiteSpace = _ref.whiteSpace,
      whiteSpace = _ref$whiteSpace === void 0 ? "nowrap" : _ref$whiteSpace,
      props = _objectWithoutProperties(_ref, _excluded);

  if (as === "nextLink") {
    return ___EmotionJSX(NextLinkButton, _extends({
      variant: variant,
      sizeVariant: sizeVariant,
      block: block,
      textTransform: textTransform,
      hoverColor: hoverColor,
      whiteSpace: whiteSpace
    }, props));
  }

  return ___EmotionJSX(AnchorLinkButton, _extends({
    variant: variant,
    sizeVariant: sizeVariant,
    block: block,
    textTransform: textTransform,
    hoverColor: hoverColor,
    whiteSpace: whiteSpace
  }, props));
};
export default LinkButton;