import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useEffect, useState } from "react";
import { Box, IconUpArrow } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var ScrollToTopComponent = styled(Box.withComponent("button", process.env.NODE_ENV === "production" ? {
  target: "e73igx00"
} : {
  target: "e73igx00",
  label: "ScrollToTopComponent"
}))(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 40px;\n  height: 36px;\n  position: fixed;\n  padding: 0;\n  bottom: 50px;\n  right: 40px;\n  z-index: 17;\n  visibility: \"visible\";\n  opacity: ", ";\n  border: 1px solid ", ";\n  border-radius: 5px;\n  background-color: ", ";\n  transition: opacity 0.3s 0s, visibility 0s 0.3s;\n\n  @media (max-width: ", ") {\n    display: none;\n  }\n"])), function (props) {
  return props.isVisible ? 1 : 0;
}, themeGet("colors.secondary"), themeGet("colors.secondary"), themeGet("breakpoints.lg"));
export var ScrollToTop = function ScrollToTop() {
  var _useState = useState(false),
      isVisible = _useState[0],
      setIsVisible = _useState[1];

  var toggleVisibility = function toggleVisibility() {
    if (window.pageYOffset > 244) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  var scrollToTop = function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(function () {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
  return ___EmotionJSX(ScrollToTopComponent, {
    isVisible: isVisible,
    "aria-label": "Scroll to Top",
    onClick: scrollToTop
  }, ___EmotionJSX(IconUpArrow, {
    color: "#FFF"
  }));
};