import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MetaTags = function MetaTags(_ref) {
  var tags = _ref.tags;
  return ___EmotionJSX(React.Fragment, null, tags.map(function (tag) {
    return ___EmotionJSX("meta", _extends({
      key: tag.itemProp
    }, tag));
  }));
};